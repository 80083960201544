import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export enum KeyboardShortcut {
  COPY = 'COPY',
  PASTE = 'PASTE',
  UNDO = 'UNDO',
  SIZE_UP = 'SIZE_UP',
  SIZE_DOWN = 'SIZE_DOWN',
  ARROW_UP = 'ARROW_UP',
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  DELETE = 'DELETE',
}

@Injectable({
  providedIn: 'root'
})
export class KeyboardService implements OnDestroy {
  private callbacks: { [key in KeyboardShortcut]?: Array<{ original: () => void, bound: () => void }> } = {};
  private keyDownSubscription!: Subscription;
  private keyUpSubscription!: Subscription;
  private activeKeys: Set<KeyboardShortcut> = new Set();
  private repeatIntervals: { [key in KeyboardShortcut]?: NodeJS.Timeout } = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private ngZone: NgZone) {
    if (isPlatformBrowser(platformId)) {
      this.keyDownSubscription = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        map(event => this.mapEventToShortcut(event)),
        filter(shortcut => !!shortcut) // Filter out undefined shortcuts
      ).subscribe(shortcut => {
        if (shortcut) {
          this.handleKeyDown(shortcut);
        }
      });

      this.keyUpSubscription = fromEvent<KeyboardEvent>(window, 'keyup').subscribe(event => {
        const shortcut = this.mapEventToShortcut(event);
        if (shortcut) {
          this.handleKeyUp(shortcut);
        }
      });
    }
  }

  private mapEventToShortcut(event: KeyboardEvent): KeyboardShortcut | undefined {
    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    const isCtrlOrCmd = isMac ? event.metaKey : event.ctrlKey;
  
    if (isCtrlOrCmd) {
      switch (event.key.toLowerCase()) {
        case 'c':
          if (!this.isInputFocused()) event.preventDefault();
          return KeyboardShortcut.COPY;
        case 'v':
          if (!this.isInputFocused()) event.preventDefault();
          return KeyboardShortcut.PASTE;
        case 'z':
          if (!this.isInputFocused()) event.preventDefault();
          return KeyboardShortcut.UNDO;
        case '=':
          event.preventDefault();
          return KeyboardShortcut.SIZE_UP;
        case '-':
          event.preventDefault();
          return KeyboardShortcut.SIZE_DOWN;
      }
    } else {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          return KeyboardShortcut.ARROW_UP;
        case 'ArrowDown':
          event.preventDefault();
          return KeyboardShortcut.ARROW_DOWN;
        case 'ArrowLeft':
          event.preventDefault();
          return KeyboardShortcut.ARROW_LEFT;
        case 'ArrowRight':
          event.preventDefault();
          return KeyboardShortcut.ARROW_RIGHT;
        case 'Backspace': 
        case 'Delete': 
        if (!this.isInputFocused()) event.preventDefault();
          return KeyboardShortcut.DELETE;
      }
    }
  
    return undefined;
  }

  private isInputFocused(): boolean {
    const activeElement = document.activeElement;
    return activeElement instanceof HTMLInputElement || 
           activeElement instanceof HTMLTextAreaElement || 
           activeElement instanceof HTMLSelectElement; // Include other form elements if needed
  }

  private handleKeyDown(shortcut: KeyboardShortcut): void {
    if (!this.isInputFocused()) {
      if (this.isSingleKeyCommand(shortcut)) {
        if (!this.activeKeys.has(shortcut)) {
          this.activeKeys.add(shortcut);
          this.ngZone.runOutsideAngular(() => {
            this.triggerCallbacks(shortcut);
            // Start repeating the callback while key is held down
            this.repeatIntervals[shortcut] = setInterval(() => {
              this.ngZone.runOutsideAngular(() => this.triggerCallbacks(shortcut));
            }, 25); // Adjust interval as needed
          });
        }
      } else {
        this.ngZone.runOutsideAngular(() => this.triggerCallbacks(shortcut));
      }
    }
  }

  private handleKeyUp(shortcut: KeyboardShortcut): void {
    if (this.isSingleKeyCommand(shortcut)) {
      if (this.activeKeys.has(shortcut)) {
        this.activeKeys.delete(shortcut);
        clearInterval(this.repeatIntervals[shortcut]);
        delete this.repeatIntervals[shortcut];
      }
    }
  }

  private isSingleKeyCommand(shortcut: KeyboardShortcut): boolean {
    return [
      KeyboardShortcut.ARROW_UP,
      KeyboardShortcut.ARROW_DOWN,
      KeyboardShortcut.ARROW_LEFT,
      KeyboardShortcut.ARROW_RIGHT
    ].includes(shortcut);
  }

  private triggerCallbacks(shortcut: KeyboardShortcut): void {
    if (this.callbacks[shortcut]) {
      this.callbacks[shortcut]!.forEach(({ bound }) => bound());
    }
  }

  public registerCallback(shortcut: KeyboardShortcut, callback: () => void, context: any): void {
    const boundCallback = callback.bind(context);

    if (!this.callbacks[shortcut]) {
      this.callbacks[shortcut] = [];
    }

    const alreadyRegistered = this.callbacks[shortcut]!.some(cb => cb.original === callback);

    if (!alreadyRegistered) {
      this.callbacks[shortcut]!.push({ original: callback, bound: boundCallback });
    }
  }

  public unregisterCallback(shortcut: KeyboardShortcut, callback: () => void): void {
    if (this.callbacks[shortcut]) {
      this.callbacks[shortcut] = this.callbacks[shortcut]!.filter(cb => cb.original !== callback);
    }
  }

  ngOnDestroy(): void {
    if (this.keyDownSubscription) {
      this.keyDownSubscription.unsubscribe();
    }
    if (this.keyUpSubscription) {
      this.keyUpSubscription.unsubscribe();
    }
    // Clear all active intervals on destroy
    for (const interval of Object.values(this.repeatIntervals)) {
      clearInterval(interval);
    }
  }
}