import { AfterViewInit, Component, DestroyRef, Inject, Input, input, NgZone, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoggedInUserComponent } from '../logged-in-user/logged-in-user.component';
import { AppMenuComponent } from '../app-menu/app-menu.component';
import { HistoryService } from '../../service/history.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SaveAndGenerateComponent } from '../save-and-generate/save-and-generate.component';
import { AppService } from '../../service/app.service';
import { Router } from '@angular/router';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageGenerationInfo, ImageGeneratorService } from '../../service/image-generator.service';
import { fromEvent, merge, take } from 'rxjs';
import { MediaType, Mode } from '../../vo/GridSection';
import { DownloadImageComponent } from '../download-image/download-image.component';
import { ToastService } from '../../service/toast.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatCommonModule } from '@angular/material/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceDetectorService } from '../../service/device-detector.service';

@Component({
  selector: 'app-top-toolbar',
  standalone: true,
  imports: [SaveAndGenerateComponent, MatTooltipModule, AppMenuComponent, LoggedInUserComponent, MatToolbarModule, MatIconModule, MatButtonModule, MatDivider, CommonModule],
  templateUrl: './top-toolbar.component.html',
  styleUrl: './top-toolbar.component.scss'
})
export class TopToolbarComponent implements AfterViewInit {

  @ViewChildren('appMenu') appMenus?: QueryList<AppMenuComponent>

  public readonly Math = Math

  private _collapsed: boolean = false

  public set collapsed(val: boolean) {
    const action: Action = { appScreen: AppScreen.HOME, action: ScreenAction.CLICK, info: "Collapse Toolbar" }
    this.analytics.track(action)
    if (isPlatformBrowser(this.platformId)) {
      if (val) {
        document.documentElement.style.setProperty('--snackbar-top', `30px`);
      } else {
        document.documentElement.style.setProperty('--snackbar-top', `140px`);
      }
    }
    this._collapsed = val
  }

  public get collapsed(): boolean {
    return this._collapsed
  }

  public isStable : boolean = false

  constructor(private ngZone : NgZone, public deviceDetector: DeviceDetectorService, private destroyRef: DestroyRef, private analytics: AnalyticsService, @Inject(PLATFORM_ID) private platformId: Object, private toast: ToastService, private imageGenerator: ImageGeneratorService, private dialog: MatDialog, public historyService: HistoryService, public appService: AppService, private router: Router) {
    this.isLandingToolbar = false
    this.ngZone.onStable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isStable = true
    });
  }

  private _isLandingToolbar: boolean = false
  @Input() public set isLandingToolbar(val: boolean) {
    this._isLandingToolbar = val
    if (isPlatformBrowser(this.platformId)) {
      if (val) {
        document.documentElement.style.setProperty('--snackbar-top', `70px`);
      } else {
        document.documentElement.style.setProperty('--snackbar-top', `140px`);
      }
    }
    //if(this.appMenu)this.appMenu.isLandingToolbar = val
  }

  public get isLandingToolbar(): boolean {
    return this._isLandingToolbar
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      merge(
        fromEvent(document, 'fullscreenchange'),
        fromEvent(document, 'webkitfullscreenchange'),
        fromEvent(document, 'mozfullscreenchange'),
        fromEvent(document, 'MSFullscreenChange')
      )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.handleFullscreenChange());

      if (this.appMenus) {
        this.appMenus.changes.subscribe(() => {
          if (this.appMenus) this.appMenus.forEach((menu) => {
            menu.isLandingToolbar = this.isLandingToolbar
          })
        })
        this.appMenus.forEach((menu) => {
          menu.isLandingToolbar = this.isLandingToolbar
        })
      }
    }
  }

  private handleFullscreenChange() {
    this.isFullScreen = !!document.fullscreenElement;
  }

  public goHome() {
    const action: Action = { appScreen: AppScreen.HOME, action: ScreenAction.GO_HOME }
    this.analytics.track(action)
    this.router.navigate(['landing'])
  }

  public isFullScreen: boolean = false;

  public fullScreen() {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    }

    this.isFullScreen = true;
  }

  public normal() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }

    this.isFullScreen = false;
  }

  public supportsFullscreen(): boolean {
    if (!isPlatformBrowser(this.platformId)) return false

    const element: HTMLElement = document.documentElement;

    return !!(
      element.requestFullscreen ||
      (element as any).mozRequestFullScreen ||
      (element as any).webkitRequestFullscreen ||
      (element as any).msRequestFullscreen
    );
  }


  private isLightTheme = false; 
  private isDarkTheme = true;

  public toggleColor() {
    this.isLightTheme = !this.isLightTheme; // Toggle light theme
    this.isDarkTheme = !this.isDarkTheme; // Toggle dark theme

    // Toggle the classes based on the theme
    document.body.classList.toggle('light-theme', this.isLightTheme);
    document.body.classList.toggle('dark-theme', this.isDarkTheme);
  }
}
