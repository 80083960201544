import { AfterViewInit, Component, ElementRef, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { GridSectionComponent } from '../grid-section/grid-section.component';
import { MemeDetailsComponent } from '../meme-details/meme-details.component';
import { AppService } from '../../service/app.service';
import { ActivatedRoute } from '@angular/router';
import { MediaType } from '../../vo/GridSection';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { isPlatformBrowser } from '@angular/common';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-main-content',
  standalone: true,
  imports: [GridSectionComponent, MemeDetailsComponent, DragDropModule],
  templateUrl: './main-content.component.html',
  styleUrl: './main-content.component.scss'
})
export class MainContentComponent implements AfterViewInit {

  public readonly MediaType = MediaType

  @ViewChild('rootGridHolder', { read: ElementRef }) rootGridHolder!: ElementRef
  @ViewChild('rootGrid') rootGrid !: GridSectionComponent

  constructor(private toastService : ToastService, @Inject(PLATFORM_ID) private platformId: Object, public appService : AppService, route : ActivatedRoute){
    //inside the service it doesnt have the right context to a route, so let's pass it in here
    appService.route = route
  }

  ngAfterViewInit(): void {
    this.appService.rootGridHolder = this.rootGridHolder.nativeElement
    
    if(isPlatformBrowser(this.platformId)){

      const visitedKey: string = "firstVisit"

      const hasVisited = localStorage.getItem(visitedKey)

      if(!hasVisited || hasVisited == 'false'){
        //this.toastService.hideAll()
        this.toastService.show([{message : 'Welcome to DankTank, the best meme creator on the internet.  Find instructional videos in the top left menu.', action : 'Thanks!', duration : 5000}])
      }

      localStorage.setItem(visitedKey, 'true')

    }
  }

  @HostListener('window:resize')
  resize() {
    if (this.rootGrid) {
      this.rootGrid.resizing = true
      setTimeout(() => {
        setTimeout(() => {
          this.rootGrid.resizing = false
        }, 100);
      }, 0);
    }
  }

}
