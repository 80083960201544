@if(isReady){ @if(deviceDetector.isPortrait()){
<div class="mobile-root">
  <app-top-toolbar [isLandingToolbar]="true"></app-top-toolbar>
  
  <div class="list-header dialog-title">
    <h1 aria-label="DankTank: Meme Generator">
      <div alt="DankTank Logo" class="header-image"></div>
    </h1>
    <h2 class="tagline">Create and share the dankest memes on the internet</h2>
  </div>

  <div class="mobile-scroller">
    <mat-card class="mobile-card" appearance="outlined">
      <mat-card-header class="mobile-dialog-title">
        <mat-card-title class="mobile-title-text">Create your own</mat-card-title>
      </mat-card-header>
      <div class="mobile-card-content">
        <div class="mobile-cta-holder">
          <div class="mobile-label-holder  pointer" (click)="ctaClicked(ContentType.BLANK)">
            <strong class="mobile-label">Start with a blank template</strong>
          </div>
          <div class="mobile-label-holder  pointer" (click)="ctaClicked(ContentType.UPLOAD)">
            <strong class="mobile-label">Upload an image</strong>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="mobile-card" appearance="outlined">
      <mat-card-header class="mobile-dialog-title">
        <mat-card-title class="mobile-title-text">Quick start</mat-card-title>
      </mat-card-header>
      <form  [ngClass]="{ 
                'list-form': true, 
                'selected-mobile-search-form': focused }">
        <mat-form-field class="search-input" appearance="outline">
          <mat-label>Search for a template</mat-label>
          <input
            #trigger="matAutocompleteTrigger"
            (blur)="onBlur()"
            (focus)="onFocus()"
            type="text"
            matInput
            [formControl]="searchInput"
            [matAutocomplete]="auto"
          />
          @if(!allOptions || allOptions.length == 0){
          <mat-spinner
            matSuffix
            diameter="35"
            style="margin-right: 10px"
          ></mat-spinner>
          }@else {
            <mat-icon class="pointer" matSuffix (click)="$event.stopPropagation(); searchInput.setValue(''); trigger.closePanel()">close</mat-icon>
          }
          <mat-autocomplete #auto="matAutocomplete" class="fade-in auto-complete">
            @for (option of filteredOptions; track option; let idx = $index) {
              @if(option.storageReference){
                <mat-option [value]="option.name" (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault()"  (click)="$event.stopImmediatePropagation(); openFromSticker(option)">
                  <img
                    #optionImg
                    class="option-image fade-img align-me"
                    (load)="optionImg.style.opacity = '1'"
                    [appLazyLoad]="getThumbFromSticker(option)"
                  />
                  <strong class="subtle-gradient-text align-me">{{
                    option.name
                  }}</strong>
                </mat-option>
              }@else {
                @if(focused){
                  <mat-option [disabled]="true" (mousedown)="$event.stopImmediatePropagation(); $event.preventDefault()" (touchstart)="$event.stopImmediatePropagation(); $event.preventDefault()">
                    <strong class="subtle-gradient-text align-me">
                      Search for: {{holderSearch}}
                      </strong>
                  </mat-option>
                }
              }
            @if(idx != filteredOptions.length - 1){
            <mat-divider></mat-divider>
            } }
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <mat-card-content class="animate-margin-top" [style.marginTop.px]="focused ? 0 : 80">
          <div class="mobile-cta-holder-horizontal-scrollable">
            <div class="mobile-img-holder  pointer" (click)="ctaClicked(ContentType.POPULAR)">
              <img
                #popularImg
                class="mobile-img "
                (load)="popularImg.style.opacity = '1'"
                [src]="getThumbFromSticker(getRandomMeme(popularImg))"
              />
              <strong class="mobile-label">Popular</strong>
            </div>
            <div class="mobile-img-holder  pointer" (click)="ctaClicked(ContentType.CLASSIC)">
              <img
                #classicImg
                class="mobile-img fade-img"
                (load)="classicImg.style.opacity = '1'"
                [src]="getThumbFromSticker(getRandomMeme(classicImg))"
              />
              <strong class="mobile-label">Classic</strong>
            </div>
            <div class="mobile-img-holder  pointer" (click)="ctaClicked(ContentType.GIF)">
              <img
                #gifImg
                class="mobile-img fade-img"
                (load)="gifImg.style.opacity = '1'"
                [src]="getThumbFromSticker(getRandomGif(gifImg))"
              />
              <strong class="mobile-label">GIF</strong>
            </div>
            <div class="mobile-img-holder  pointer" (click)="memeAtRandom()">
              <img
                #randomImg
                class="mobile-img fade-img"
                (load)="randomImg.style.opacity = '1'"
                [src]="getThumbFromSticker(getRandomMeme(randomImg))"
              />
              <strong class="mobile-label">Random</strong>
            </div>

            <div class="mobile-img-holder  pointer" (click)="ctaClicked(ContentType.SAVED)">
              <img
                #draftImg
                class="mobile-img fade-img"
                (load)="draftImg.style.opacity = '1'"
                src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/drafts.svg"
              />
              <strong class="mobile-label">Drafts</strong>
            </div>
          </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

}@else{
<div class="pc-root">
<app-top-toolbar [isLandingToolbar]="true"></app-top-toolbar>
<div class="top-bg">
  <div class="top">
    <div class="top-left">
      <h1 class="tag-line">
        Create and share the dankest memes on the internet
      </h1>
      <form [class]="focused ? 'search-form-selected' : 'search-form'">
        <mat-form-field class="search-input" appearance="outline">
          <mat-label>Search for a template</mat-label>
          <input
            #pcInput
            (blur)="onBlur()"
            (focus)="onFocus()"
            #trigger="matAutocompleteTrigger"
            type="text"
            matInput
            [formControl]="searchInput"
            [matAutocomplete]="auto"
          />
          @if(!allOptions || allOptions.length == 0){
          <mat-spinner
            matSuffix
            diameter="35"
            style="margin-right: 10px"
          ></mat-spinner>
          }@else {
          <mat-icon
            matSuffix
            class="pointer"
            (mousedown)="
              $event.stopImmediatePropagation(); $event.preventDefault()
            "
            (touchstart)="
              $event.stopImmediatePropagation(); $event.preventDefault()
            "
            (click)="searchInput.setValue(''); trigger.closePanel()"
            >close</mat-icon
          >
          }
          <mat-autocomplete #auto="matAutocomplete" class="fade-in">
            @for (option of filteredOptions; track option; let idx = $index) {
            @if(option.storageReference){
            <mat-option
              [value]="option.name"
              (mousedown)="
                $event.stopImmediatePropagation(); $event.preventDefault()
              "
              (touchstart)="
                $event.stopImmediatePropagation(); $event.preventDefault()
              "
              (click)="openFromSticker(option)"
            >
              <img
                #optionImg
                class="option-image fade-img align-me"
                (load)="optionImg.style.opacity = '1'"
                [appLazyLoad]="getThumbFromSticker(option)"
              />
              <strong class="subtle-gradient-text align-me">{{
                option.name
              }}</strong>
            </mat-option>
            }@else {
            <mat-option
              [disabled]="true"
              (mousedown)="
                $event.stopImmediatePropagation(); $event.preventDefault()
              "
              (touchstart)="
                $event.stopImmediatePropagation(); $event.preventDefault()
              "
            >
              <strong class="subtle-gradient-text align-me">
                Search for: {{ holderSearch }}
              </strong>
            </mat-option>
            } @if(idx != filteredOptions.length - 1){
            <mat-divider></mat-divider>
            } }
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div class="top-right">
      <img
        #logo
        (load)="logo.style.opacity = '1'"
        class="logo fade-img"
        src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/logos/logo-500_x_500_trimmed.png"
      />
    </div>
  </div>
</div>
<div class="bottom">
  <div class="bottom-tiles-container">
    <div class="create-a-meme">
      <h2>Create a Meme</h2>
    </div>
    <div class="bottom-tiles">
      @if(!allOptions || allOptions.length == 0){
      <mat-spinner style="float: left !important"></mat-spinner>
      }@else{
      <div class="tiles">
        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="memeAtRandom()"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text">Random</mat-card-title>
          </mat-card-header>
          @if(random.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              random.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #random
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="random.style.opacity = '1'"
            [src]="getThumbFromSticker(getRandomMeme(random))"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            A random meme from our collection
          </mat-card-content>
        </mat-card>

        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.POPULAR)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text"
              >Popular Meme Templates</mat-card-title
            >
          </mat-card-header>
          @if(popularImg.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              popularImg.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #popularImg
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="popularImg.style.opacity = '1'"
            [src]="getThumbFromSticker(getRandomMeme(popularImg))"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            Our users most popular templates
          </mat-card-content>
        </mat-card>

        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.CLASSIC)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text mat-header-text"
              >Classic Meme Templates</mat-card-title
            >
          </mat-card-header>
          @if(classic.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              classic.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #classic
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="classic.style.opacity = '1'"
            [src]="getThumbFromSticker(getRandomMeme(classic))"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            Classic memes you know and love
          </mat-card-content>
        </mat-card>

        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.GIF)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text"
              >GIF Templates</mat-card-title
            >
          </mat-card-header>
          @if(gif.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              gif.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #gif
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="gif.style.opacity = '1'"
            [src]="getThumbFromSticker(getRandomGif(gif))"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            Movement on the screen!
          </mat-card-content>
        </mat-card>

        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.BLANK)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text"
              >Blank Template</mat-card-title
            >
          </mat-card-header>
          @if(blank.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              blank.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #blank
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="blank.style.opacity = '1'"
            src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/blank.svg"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            A blank slate for you to go wild!
          </mat-card-content>
        </mat-card>

        @if(hasSaved) {
        <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.SAVED)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text"
              >Your Drafts</mat-card-title
            >
          </mat-card-header>
          @if(drafts.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              drafts.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #drafts
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="drafts.style.opacity = '1'"
            src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/drafts.svg"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            Memes you've worked so hard on
          </mat-card-content>
        </mat-card>
        }@else {
          <!--https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/upload.svg-->
          <mat-card
          class="tile pointer fade-in"
          appearance="outlined"
          (click)="ctaClicked(ContentType.UPLOAD)"
        >
          <mat-card-header>
            <mat-card-title class="subtle-gradient-text"
              >Upload</mat-card-title
            >
          </mat-card-header>
          @if(upload.style.opacity != '1' && !shouldCollapse()){
          <mat-spinner class="load-spinner" diameter="50"></mat-spinner>
          }
          <mat-divider
            class="tile-divider"
            [style.opacity]="
              upload.style.opacity != '1' && !shouldCollapse() ? 0 : 1
            "
          ></mat-divider>

          <img
            #upload
            [style.height.px]="shouldCollapse() ? 0 : null"
            class="tile-image fade-img"
            (load)="upload.style.opacity = '1'"
            src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/upload.svg"
          />
          <mat-card-content class="subtle-gradient-text graduated-text">
            Upload something!
          </mat-card-content>
        </mat-card>
        }
      </div>
      }
    </div>
    <div class="tos-bottom">
      <button
        mat-button
        class="pointer linkButton"
        matRippleDisabled="true"
        (click)="showLegal()"
      >
        TOS / Privacy Policy
      </button>
    </div>
  </div>
</div>
</div>
}
}
