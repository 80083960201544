import { DestroyRef, ElementRef, inject, Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Asset, GridSection, Media, MediaType, Mode } from '../vo/GridSection';
import { GridSectionComponent } from '../component/grid-section/grid-section.component';
import { MetaService, MetaTag } from './meta.service';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { EditModeData, ImageEditedData, ToolbarService } from './toolbar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Font, FontService } from './font.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Sticker, StickerPack, StickerService, StickerServiceResponse } from './sticker.service';
import { Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { createAssetsFromJSON } from '../util/AssetCreator';
import { AspectRatio } from '../vo/Pickers';
import { Dialog } from '@angular/cdk/dialog';
import { Toast, ToastService } from './toast.service';
import { LoaderComponent } from '../component/loader/loader.component';
import { Action, AnalyticsService, AppScreen, ScreenAction } from './analytics.service';
import { DankTankAuthService } from './dank-tank-auth.service';
import { PaidFeature, PaymentService } from './payment.service';
import { PresetsService } from './presets.service';
import { MatDialog } from '@angular/material/dialog';

export class Measurements {
  public width !: number
  public height !: number
  constructor(w: number, h: number) {
    this.width = w
    this.height = h
  }
}

export interface ScalingFactors {
  overall: number;
  positionX: number;
  positionY: number;
}


@Injectable({
  providedIn: 'root'
})
export class AppService {

  public video?: HTMLVideoElement
  public image?: HTMLImageElement
  public rootGridHolder?: HTMLDivElement

  private _gridSection?: GridSection
  public get gridSection(): GridSection | undefined {
    return this._gridSection;
  }
  public set gridSection(gs: GridSection | undefined) {
    //this.visitedCheck()
    if (this._gridSection && gs) {
      gs.assets = this._gridSection?.assets
      gs.verticalAspectRatio = this._gridSection?.verticalAspectRatio
      gs.horizontalAspectRatio = this._gridSection?.horizontalAspectRatio
      gs.mode = this._gridSection?.mode || Mode.DEVELOPMENT
      gs.borderColor = this._gridSection?.borderColor
      gs.backgroundColor = this._gridSection?.backgroundColor
      gs.borderWidth = this._gridSection?.borderWidth
      gs.borderStyle = this._gridSection?.borderStyle

      const previousMedia: Array<Media> = this._gridSection?.collectMedia() || [];
      gs.distributeMedia(previousMedia);
    }

    this._gridSection = gs

  }

  public set gridSectionSimple(gs: GridSection | undefined) {
    this._gridSection = gs
  }

  public tourMode: boolean = false
  public sf?: ScalingFactors
  public ready: boolean = false


  public isDeepLink: boolean = false
  public editData?: EditModeData
  public imageEditData?: ImageEditedData


  private readonly document = inject(DOCUMENT);

  constructor(private router: Router, private authService: DankTankAuthService, private paymentService: PaymentService, private analyticsService: AnalyticsService, private stickerService: StickerService, private toast: ToastService, private dialog: MatDialog, private zone: NgZone, private destroyRef: DestroyRef, @Inject(PLATFORM_ID) private platformId: Object, private meta: MetaService, private toolbarService: ToolbarService, private fontService: FontService) {

  }

  private _route?: ActivatedRoute
  public set route(route: ActivatedRoute) {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
    this._route = route
    this.watchRoutes()
  }

  public get route(): ActivatedRoute | undefined {
    return this._route
  }

  private _measurements?: Measurements
  public getMeasurements(rootGrid: GridSectionComponent, rootGridHolder: HTMLDivElement): Measurements {
    //if (!this.rootGridHolder) return new Measurements(0, 0);
    if(!isPlatformBrowser(this.platformId))return new Measurements(0, 0)

    if (this.gridSection) {
      this.gridSection.viewPortWidth = window.innerWidth;
      this.gridSection.viewPortHeight = window.innerHeight;
    }

    // Calculate the original dimensions
    let originalWidth = rootGridHolder.offsetWidth * 0.95; // 95% of the width
    let originalHeight = rootGridHolder.offsetHeight * 0.95; // 95% of the height

    if (this.gridSection?.media?.sticker?.template && this.gridSection?.media?.sticker?.template.width && this.gridSection?.media?.sticker?.template.height) {
      if (this.gridSection?.media?.sticker?.template.width * 0.95 < originalWidth) {
        originalWidth = this.gridSection?.media?.sticker?.template.width * 0.95
      }
      if (this.gridSection?.media?.sticker?.template.height * 0.95 < originalHeight) {
        originalHeight = this.gridSection?.media?.sticker?.template.height * 0.95
      }
    }

    // Get the padding height (foreheadHeight)
    const paddingHeight = this.gridSection?.forehead || 0;

    // Calculate the aspect ratio
    const horizontalAspectRatio = this.gridSection?.horizontalAspectRatio || 1;
    const verticalAspectRatio = this.gridSection?.verticalAspectRatio || 1;
    const aspectRatio = horizontalAspectRatio / verticalAspectRatio;

    // Start with dimensions based on height
    let finalHeight = originalHeight;
    let finalWidth = finalHeight * aspectRatio;

    // Adjust finalWidth for padding
    finalWidth -= finalWidth * (paddingHeight / finalHeight);

    // Ensure dimensions fit within available space
    if (finalWidth > originalWidth) {
      // Adjust width to fit within the original width
      finalWidth = originalWidth;
      finalHeight = finalWidth / aspectRatio;

      // Adjust finalHeight to account for padding
      finalHeight += paddingHeight;

      // If adjusted finalHeight exceeds originalHeight, adjust finalHeight
      if (finalHeight > originalHeight) {
        finalHeight = originalHeight;
        finalWidth = finalHeight * aspectRatio;
      }
    } else if (finalHeight > originalHeight) {
      // If height exceeds the available height, adjust height and calculate width accordingly
      finalHeight = originalHeight;
      finalWidth = finalHeight * aspectRatio;
    }

    // Set the grid section dimensions
    if (this.gridSection) {
      let element: any;
      if (rootGrid.images && rootGrid.images.first) {
        element = rootGrid.images.first.nativeElement;
      }
      if (rootGrid.videos && rootGrid.videos.first) {
        element = rootGrid.videos.first.nativeElement;
      }
      const elementHeight = element ? element.clientHeight : finalHeight;
      const elementWidth = element ? element.clientWidth : finalWidth;

      const sf = this.calculateScalingFactor(finalWidth, finalHeight);


      const foreheadDiff: number = this.gridSection.forehead - this.oldForeheadHeight
      this.gridSection.assets.forEach((asset: Asset) => {
        if (this.gridSection) {
          const xAndY: { x: number, y: number } = this.gridSection.getMatrixForAsset(asset)
          let x = xAndY.x
          let y = xAndY.y

          const assetY: number = y
          const relativeY: number = assetY / finalHeight

          const adjustmentFactor: number = 1 - relativeY
          const adjustedY = assetY + foreheadDiff * adjustmentFactor;

          //asset.data.y = adjustedY
          this.gridSection.updateMatrixOnAsset(asset, undefined, adjustedY)
        }
      })

      if (sf.overall < 3 && sf.overall !== Infinity) this.gridSection.adjustAssets(sf);

      this.oldForeheadHeight = this.gridSection.forehead

      this.gridSection.rootGridSectionWidth = finalWidth;
      this.gridSection.rootGridSectionHeight = finalHeight;
    }


    this._measurements = new Measurements(finalWidth, finalHeight);
    return this._measurements;
  }

  private oldForeheadHeight: number = 0

  private calculateScalingFactor(w: number, h: number): ScalingFactors {
    const initialWidth = this._measurements?.width || 0
    const initialHeight = this._measurements?.height || 0
    const currentWidth = w;
    const currentHeight = h;

    const widthScalingFactor = currentWidth / initialWidth;
    const heightScalingFactor = currentHeight / initialHeight;

    // Choose the smaller scaling factor to maintain aspect ratio (optional)
    let overallScalingFactor;

    if (currentHeight > initialHeight || currentWidth > initialWidth) {
      overallScalingFactor = Math.max(widthScalingFactor, heightScalingFactor)
    } else {
      overallScalingFactor = Math.min(widthScalingFactor, heightScalingFactor)
    }

    return {
      overall: overallScalingFactor,
      positionX: this.calculatePositionScalingFactor(initialWidth, currentWidth),
      positionY: this.calculatePositionScalingFactor(initialHeight, currentHeight),
    } as ScalingFactors
  }

  private calculatePositionScalingFactor(initialDimension: number, currentDimension: number): number {
    return initialDimension === 0 ? 1 : currentDimension / initialDimension;
  }



























  public initialGridReady: Subject<GridSection> = new Subject()

  private readonly subscriptions: Subscription[] = []
  ///PARSING INCOMING ROUTES
  async watchRoutes() {
    this.meta.setDefaultMetaTags()

    //this.adSize = this.deviceDetector.getAdSize()


    this.subscriptions.push(this.toolbarService.editModeSubject.asObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: EditModeData) => {
        this.editData = data
      }
    }))

    this.subscriptions.push(this.toolbarService.imageEditedSubject.asObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: ImageEditedData) => {
        this.imageEditData = data
      }
    }))

    if (this.route) {

      this.subscriptions.push(this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {

        //this.meta.setDefaultMetaTags()
        const id: string | null = params.get('id')
        if (id) {
          this.isDeepLink = true
          console.log("LOADING MEMES")
          let format = this?.route?.snapshot.queryParamMap.get('format')
          if (!format || format.length < 1) format = 'meme'
          const obs: Observable<StickerServiceResponse> = format == 'meme' ? this.stickerService.getMemePackData() : this.stickerService.getGifPackData()
          obs.pipe(take(1)).subscribe({
            next: (data: StickerServiceResponse) => {
              console.log("LOADED MEMES")
              if (data.data) {
                let foundMeme: Sticker | undefined = undefined
                const memePacks: StickerPack[] = data.data.collection
                for (let i = 0; i < memePacks.length; i++) {
                  const memePack: StickerPack = memePacks[i]
                  for (let j = 0; j < memePack.stickers.length; j++) {
                    const meme = memePack.stickers[j]
                    if (meme.name == id) foundMeme = meme
                  }
                }
                if (foundMeme) {
                  //setTimeout(() => {
                  const uri = format == 'meme' ? `${environment.storage}media.danktank.cloud/memes%2Ffull%2F${foundMeme.storageReference}` : `${environment.storage}media.danktank.cloud/gifs/mp4/${foundMeme.storageReference}.mp4`
                  const metaURI = format == 'meme' ? `${environment.storage}media.danktank.cloud/memes%2Ffull%2F${foundMeme.storageReference}` : `${environment.storage}media.danktank.cloud/gifs/thumb/${foundMeme.storageReference}.webp`
                  this.meta.setDefaultMetaTags()
                  this.meta.setSpecificTag(MetaTag.TITLE, foundMeme?.name || '')
                  this.meta.setSpecificTag(MetaTag.DESCRIPTION, foundMeme?.name || '')
                  this.meta.setSpecificTag(MetaTag.IMAGE, metaURI || '')
                  this.meta.setSpecificTag(MetaTag.URL, this.document.URL || '')



                  const gs: GridSection = new GridSection()
                  gs.name = foundMeme.name
                  //this.redoGridSection(gs)
                  this.gridSection = gs
                  if (!isPlatformBrowser(this.platformId))return
                  const m: Media = { loaded: false, sticker: foundMeme, blur: 0, sepia: 0, grey: 0, hue: 0, saturation: 1, brightness: 1, mediaType: format == 'meme' ? MediaType.LOCAL_IMAGE : MediaType.VIDEO, mediaLocation: uri || '', originalLocation: uri || '', name: foundMeme?.name }
                  if (this.gridSection) {
                    this.gridSection.media = m


                    if (this.gridSection) createAssetsFromJSON(this.gridSection, this.toolbarService, this.fontService, foundMeme, this.zone, isPlatformBrowser(this.platformId)).pipe(take(1)).subscribe(() => {
                      const horizontalAspectRatio = this.gridSection?.horizontalAspectRatio ?? 0
                      const verticalAspectRatio = this.gridSection?.verticalAspectRatio ?? 0

                      setTimeout(() => {
                        if (this.gridSection) this.initialGridReady.next(this.gridSection)
                      }, 1);


                      //if (this.dialog.openDialogs.length == 0) {
                      if (isPlatformBrowser(this.platformId)) {
                        if ((this.gridSection?.assets.length || 0) > 0) {
                          this.toast.show([{ message: "Double click text elements to edit!", action: "Got it!" }, { message: "Use the buttons on the toolbar to style text!", action: "Got it!" }])
                        } else {
                          //this.assetTrigger.openMenu()
                          this.toast.show([{ message: 'Add text, stickers, emojis and more on the bottom toolbar', action: 'Got it!' }])
                          this.toast.show([{ message: 'How-to videos, and other useful items in the top left menu', action: 'Got it!' }])
                        }
                      }
                      // }
                    })
                  }
                  //}, this.ready ? 0 : 0);
                } else {
                  this.gridSection = new GridSection() //this.presetsService.gridSections[0].data
                  this.setAspectRatio(new AspectRatio(1, 1, '1:1'))
                  //this.initialGridReady.next(this.gridSection)
                }
              }
            }
          })
        }
        
        /*
        const format = this?.route?.snapshot.queryParamMap.get('format')
        const obs: Observable<StickerServiceResponse> = format == 'meme' ? this.stickerService.getMemePackData() : this.stickerService.getGifPackData()
        this.authService.authThen(this.fakeAuthCallback, this, this.paymentService.getAllPaid(), PaidFeature.GIFS, this.goHome)
        */

        
        if (isPlatformBrowser(this.platformId)) {
          const format = this?.route?.snapshot.queryParamMap.get('format')
          const obs: Observable<StickerServiceResponse> = format == 'meme' ? this.stickerService.getMemePackData() : this.stickerService.getGifPackData()
          //this.getSaveMemesCount()
          if (!this.ready && window.history.length == 1) {
            this.dialog.open(LoaderComponent, { disableClose: true, width: '200px' }).afterClosed().pipe(take(1)).subscribe(d => {
              this.ready = true
              const gs: GridSection = this.gridSection || new GridSection()
              this.gridSection = gs
              //force them to upgrade in case of gifs
              if (format == 'gif') {
                //we're not going to do this now so they can play with gifs
                //this.authService.authThen(this.fakeAuthCallback, this, this.paymentService.getAllPaid(), PaidFeature.GIFS, this.goHome)
              }
            })
          } else {
            this.ready = true
            this.gridSection = new GridSection() //this.presetsService.gridSections[0].data
            this.setAspectRatio(new AspectRatio(1, 1, '1:1'))
            //force them to upgrade in case of gifs
            if (format == 'gif') {
              //we're not going to do this now so they can play with gifs
              //this.authService.authThen(this.fakeAuthCallback, this, this.paymentService.getAllPaid(), PaidFeature.GIFS, this.goHome)
            }
          }
        } else {
          //this.ready = true
        }
          
      }))
    }
  }

  private fakeAuthCallback() {
    //we need to have this because auththen requires a callback, we could change this with a little refactor
  }

  public setAspectRatio(ar: AspectRatio) {
    if (this.gridSection) {
      this.gridSection.horizontalAspectRatio = ar.horizontalAspectRatio
      this.gridSection.verticalAspectRatio = ar.verticalAspectRatio
      this.a('Aspect Ration', `${ar.horizontalAspectRatio} / ${ar.verticalAspectRatio}`)
    }
  }

  public goHome() {
    this.router.navigate(['landing'])
  }


  readonly ScreenAction = ScreenAction
  a(n: string, d?: string, sa?: ScreenAction) {
    const action: Action = { appScreen: AppScreen.HOME, action: sa || ScreenAction.SET_PROPERTY, info: { name: n, data: d } }
    this.analyticsService.track(action)
  }

}