import { Component, DestroyRef, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Asset, AssetType, GridSection } from '../../vo/GridSection';
import { Sticker, StickerPack, StickerService, StickerServiceResponse } from '../../service/sticker.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import { ImageResponse, SocialService } from '../../service/social.service';
import { createAssetsFromJSON } from '../../util/AssetCreator';
import { Router } from '@angular/router';
import { PopularMeme } from '../popular-memes/popular-memes.component';
import { Observable, Subject, take } from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import { FontService } from '../../service/font.service';
import { ToastService } from '../../service/toast.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';

enum ImageLoadStatus {
  LOADED = "LOADED",
  ERROR = "ERROR",
}

type ImageStatusMap = Record<string, ImageLoadStatus>

@Component({
  selector: 'app-sticker-browser',
  standalone: true,
  imports: [MatChipsModule, LazyLoadDirective, ScrollingModule, FormsModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatIconModule, MatGridListModule, MatDividerModule, MatDialogModule, MatButtonModule, MatExpansionModule, MatProgressSpinnerModule, MatCardModule, MatListModule],
  templateUrl: './sticker-browser.component.html',
  styleUrl: './sticker-browser.component.scss'
})
export class StickerBrowserComponent implements OnInit {

  readonly AssetType = AssetType

  private _searchTerm: string = ''
  public set searchTerm(t: string) {
    this._searchTerm = t
    this.searchResults = this.filterStickers()
  }

  public get searchTerm(): string {
    return this._searchTerm
  }

  public searchResults: Sticker[] = [];

  public stickerPacks: StickerPack[] = []
  public selectedPack?: StickerPack;
  public readonly images: ImageStatusMap = {

  }

  public readonly ImageLoadStatus = ImageLoadStatus

  constructor(private zone: NgZone, @Inject(PLATFORM_ID) private platformId: Object, private toast: ToastService, private fontService: FontService, private router: Router, private socialService: SocialService, private history: HistoryService, private analyticsService: AnalyticsService, private toolbarService: ToolbarService, private destroyRef: DestroyRef, private dialogRef: MatDialogRef<StickerBrowserComponent>, @Inject(MAT_DIALOG_DATA) public config: Asset, private stickerService: StickerService) {

  }

  ngOnInit(): void {
    if (this.config.type == AssetType.STICKER) {
      this.stickerService.getStickerPackData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (data: StickerServiceResponse) => {
          if (data.data) {
            this.stickerPacks = data.data.collection.sort((a, b) => a.name.localeCompare(b.name))
            this.a("Sticker packs loaded", '', ScreenAction.LOADED)
          } else if (data.error) {

          }
        }
      })
    } else if (this.config.type == AssetType.MEME) {
      this.stickerService.getMemePackData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (data: StickerServiceResponse) => {
          if (data.data) {
            if (data.data) this.stickerPacks = data.data.collection
            this.a("Meme packs loaded", '', ScreenAction.LOADED)
            /*
            const toSet : StickerPack[] = data.data.collection.sort((a, b) => a.name.localeCompare(b.name))
            const exists = toSet.some(obj => obj.name === "Most Used Memes");
            if(exists){
              if(data.data)this.stickerPacks = toSet
            }else{
              this.getMostUsedMemes(toSet).pipe(take(1)).subscribe(() =>{
                if(data.data)this.stickerPacks = toSet
                this.a("Meme packs loaded", '', ScreenAction.LOADED)
              })
            }
              */
          } else if (data.error) {

          }
        }
      })
    } else if (this.config.type == AssetType.EMOJI) {
      this.stickerService.getEmojiPackData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (data: StickerServiceResponse) => {
          if (data.data) {
            this.stickerPacks = data.data.collection.sort((a, b) => a.name.localeCompare(b.name))
            this.a("Emoji packs loaded", '', ScreenAction.LOADED)
          } else if (data.error) {

          }
        }
      })
    } else if (this.config.type == AssetType.SHAPE) {
      this.stickerService.getShapePackData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (data: StickerServiceResponse) => {
          if (data.data) {
            this.stickerPacks = data.data.collection.sort((a, b) => a.name.localeCompare(b.name))
            this.a("Shape packs loaded", '', ScreenAction.LOADED)
          } else if (data.error) {

          }
        }
      })
    } else if (this.config.type == AssetType.GIF) {
      this.stickerService.getGifPackData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (data: StickerServiceResponse) => {
          if (data.data) {
            this.stickerPacks = data.data.collection.sort((a, b) => a.name.localeCompare(b.name))
            this.a("GIF packs loaded", '', ScreenAction.LOADED)
          } else if (data.error) {

          }
        }
      })
    }
    this.dialogRef.beforeClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(d => {
      this.cancel()
    })
  }



  private getStickerFromMeme(meme: PopularMeme, stickerPacks: StickerPack[]): Sticker | undefined {
    for (let i = 0; i < (stickerPacks?.length || 0); i++) {
      const pack = stickerPacks?.[i]
      for (let j = 0; j < (pack?.stickers.length || 0); j++) {
        const sticker = pack?.stickers?.[j]
        if (sticker?.storageReference.includes(meme.storageReference)) {
          console.log("FOUND STICKER")
          return sticker
        }
      }
    }
    return undefined
  }

  private getMostUsedMemes(stickerPacks: StickerPack[]): Observable<boolean> {
    const obs: Subject<boolean> = new Subject()
    const popularMemes: PopularMeme[] = []
    const stickers: Sticker[] = []

    this.socialService.imageViews()

    this.socialService.viewsSubject.asObservable().pipe(take(1)).subscribe({
      next: (d: ImageResponse) => {
        if (d.data) {
          for (let prop in (d.data as any)) {
            popularMemes.push({ storageReference: prop.split('+').join('.'), views: (d.data as any)[prop] })
          }
          popularMemes.sort((a, b) => b.views - a.views)

          popularMemes.forEach((meme: PopularMeme) => {
            const s: Sticker | undefined = this.getStickerFromMeme(meme, stickerPacks)
            if (s) stickers.push(s)
          })

          const pack: StickerPack = { name: "Most Used Memes", description: "Our top used Memes", stickers: stickers }

          if (stickers && stickers.length > 0) stickerPacks.unshift(pack)

          obs.next(true)
        }
      }
    })
    return obs.asObservable()
  }

  getTitle(onlyTitle: boolean = false): string {
    let toReturn = ''
    switch (this.config.type) {
      case AssetType.EMOJI:
        toReturn = "Emojis"
        break
      case AssetType.STICKER:
        toReturn = "Stickers"
        break
      case AssetType.MEME:
        toReturn = "Memes"
        break
      case AssetType.GIF:
        toReturn = "GIFS"
        break
      case AssetType.SHAPE:
        toReturn = "Shape"
        break
    }
    return this.searchResults && this.searchResults.length > 0 && !onlyTitle
      ? `${toReturn} - ${this.searchTerm} (${this.searchResults.length})`
      : (this.searchResults && this.searchResults.length === 0 && this.searchTerm.length > 2
        ? `${toReturn} - No results for ${this.searchTerm}`
        : toReturn);
  }

  private cache = new Map<string, Sticker[]>();

  private filterStickers(): Sticker[] {
    if (!this.searchTerm || this.searchTerm.length < 3) return [];

    const searchTerm = this.searchTerm.toLowerCase();

    // Check if the result is cached
    const cachedResult = this.cache.get(searchTerm);
    if (cachedResult) {
      return cachedResult;
    }

    let allStickers: Sticker[] = [];
    if (this.selectedPack) {
      allStickers = this.selectedPack.stickers;
    } else {
      this.stickerPacks.forEach((sp: StickerPack) => {
        allStickers.push(...sp.stickers);
      });
    }

    // Preprocess search terms
    const searchTerms = searchTerm.split(' ');

    let filteredStickers: Sticker[] = allStickers.filter((sticker: Sticker) => {
      const stickerName = sticker.name.toLowerCase();
      const stickerTags = sticker.tags ? sticker.tags.toLowerCase() : '';
      return searchTerms.some(val => val.length > 2 && (stickerName.includes(val) || stickerTags.includes(val)));
    });

    if (!this.selectedPack) {
      this.stickerPacks.forEach((stickerPack: StickerPack) => {
        if (stickerPack.name.toLowerCase().includes(searchTerm)) {
          filteredStickers.push(...stickerPack.stickers);
        }
      });
    }

    // Deduplicate stickers
    filteredStickers = filteredStickers.reduce((acc, sticker) => {
      const key = `${sticker.name.toLowerCase()}-${sticker.type}`;
      if (!acc.seen.has(key)) {
        acc.seen.add(key);
        acc.unique.push(sticker);
      }
      return acc;
    }, { seen: new Set<string>(), unique: [] as Sticker[] }).unique;

    // Sort unique stickers by name with exact match prioritization
    filteredStickers.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return nameA === searchTerm && nameB !== searchTerm ? -1 :
        nameB === searchTerm && nameA !== searchTerm ? 1 :
          nameA.localeCompare(nameB);
    });

    // Cache the result
    this.cache.set(searchTerm, filteredStickers);

    this.a("Search " + this.config.type.toString(), `${this.searchTerm} ${filteredStickers.length}`, ScreenAction.SEARCH);
    return filteredStickers;
  }

  public uriFromSticker(sticker?: Sticker, isFull?: Boolean): string {
    if (!sticker) sticker = this.selectedPack?.stickers[0]
    if (!sticker) return ''
    if (this.config.type == AssetType.STICKER || this.config.type == AssetType.EMOJI || this.config.type == AssetType.SHAPE) {
      return `${environment.storage}media.danktank.cloud/${sticker.storageReference}`
    } else if (this.config.type == AssetType.MEME) {
      if (isFull) {
        return `${environment.storage}media.danktank.cloud/memes%2Ffull%2F${sticker.storageReference}`
      } else {
        return `${environment.storage}media.danktank.cloud/memes%2Fthumb%2F${sticker.storageReference}`
      }
    } else if (this.config.type == AssetType.GIF) {
      if (isFull) {
        return `${environment.storage}media.danktank.cloud/gifs%2Fmp4%2F${sticker.storageReference}.mp4?alt=media`
      } else {
        return `${environment.storage}media.danktank.cloud/gifs%2Fthumb%2F${sticker.storageReference}.webp?alt=media`
      }
    }
    return ""
  }

  getPackFromSticker(sticker: Sticker): StickerPack | undefined {
    for (let i = 0; i < this.stickerPacks.length; i++) {
      const pack = this.stickerPacks[i]
      for (let j = 0; j < pack.stickers.length; j++) {
        const s = pack.stickers[j]
        if (sticker == s) return pack
      }
    }
    return undefined
  }

  cancel() {
    if (this.config.data.location || this.config.convertToMedia) return
    //this.history.undo()
    this.config.section?.removeAsset(this.history, this.config)
    this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
    this.a("Backed out of asset browser", '', ScreenAction.CANCEL)
  }

  public selectSticker(sticker: Sticker) {
    if (this.config.convertToMedia) {

      //this.history.createHistoryEntry(HistoryRecordType.MEDIA)
      if (this.config.section?.assets.length == 0 && this.config.section.isRootGrid && (this.config.type == AssetType.MEME || this.config.type == AssetType.GIF)) {
        //this.router.navigate(['generator', 'meme', sticker?.name])
        if (this.config.type == AssetType.GIF) {
          this.router.navigate(['generator', 'meme', sticker?.name], { queryParams: { format: 'gif' } });
        } else if (this.config.type == AssetType.MEME) {
          this.router.navigate(['generator', 'meme', sticker?.name], { queryParams: { format: 'meme' } });
        }
      } else {
        this.config.data.location = this.uriFromSticker(sticker, true)
        this.config.convertToMedia.mediaLocation = this.config.convertToMedia.originalLocation = this.uriFromSticker(sticker, true)

        if (this.config.section?.isRootGrid) createAssetsFromJSON(this.config.section || new GridSection(), this.toolbarService, this.fontService, sticker, this.zone, isPlatformBrowser(this.platformId))
      }

      this.dialogRef.close()
    } else {
      this.config.data.location = this.uriFromSticker(sticker, true)
      //this.toolbarService.editModeSubject.next({ mode: EditMode.STICKER, asset: this.config })
      this.toast.show([{ message: "Drag, resize or rotate!  Also use buttons in toolbar to change other properties!", action: "Got it!" }])
      this.dialogRef.close()

    }

    if (this.config.type == AssetType.MEME || this.config.type == AssetType.GIF) {
      this.socialService.imageViewed(sticker)
    }

    this.a("Sticker Selected", sticker.name + " " + sticker.storageReference, ScreenAction.CLICK)
  }

  ////////////////Analytics stuff
  readonly ScreenAction = ScreenAction
  a(n: string, d?: string, sa?: ScreenAction) {
    const action: Action = { appScreen: AppScreen.ASSET_CHOOSER, action: sa || ScreenAction.SET_PROPERTY, info: { name: n, data: d } }
    this.analyticsService.track(action)
  }
}
