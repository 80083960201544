import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
//@ts-ignore 
//import WebFont from '../util/webfontloader';  

//type WebFontModule = typeof import('../util/webfontloader');

//@ts-ignore 
//type WebFontType = typeof import('../util/webfontloader').WebFont;

export interface Font {
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class FontService {

  constructor(private sanitizer: DomSanitizer, @Inject(PLATFORM_ID) protected platformId: Object) {}

  private fonts: Array<Font> = [
    { name: 'Shadows Into Light' },
    { name: 'Averia Sans Libre' },
    { name: 'Dancing Script' },
    { name: 'Cabin' },
    { name: 'Bitter' },
    { name: 'Source Code Pro' },
    { name: 'Teko' },
    { name: 'Tiny5' },
    { name: 'Caveat' },
    { name: 'Playwrite Indonesia' },
    { name: 'Maven Pro' },
    { name: 'Permanent Marker' },
    { name: 'Black Ops One' },
    { name: 'Roboto' },
    { name: 'Open Sans' },
    { name: 'Lato' },
    { name: 'Montserrat' },
    { name: 'Oswald' },
    { name: 'Source Sans Pro' },
    { name: 'Slabo 27px' },
    { name: 'Raleway' },
    { name: 'Poppins' },
    { name: 'Merriweather' },
    { name: 'Roboto Condensed' },
    { name: 'Noto Sans' },
    { name: 'Playfair Display' },
    { name: 'Ubuntu' },
    { name: 'PT Sans' },
    { name: 'PT Serif' },
    { name: 'Noto Serif' },
    { name: 'Rubik' },
    { name: 'Work Sans' },
    { name: 'Nunito' },
    { name: 'Nunito Sans' },
    { name: 'Inconsolata' },
    { name: 'Fira Sans' },
    { name: 'Quicksand' },
    { name: 'Heebo' },
    { name: 'Arimo' },
    { name: 'Mukta' },
    { name: 'Josefin Sans' },
    { name: 'Libre Franklin' },
    { name: 'Oxygen' },
    { name: 'Lora' },
    { name: 'Nanum Gothic' },
    { name: 'Anton' },
    { name: 'Varela Round' },
    { name: 'Tajawal' },
    { name: 'Cormorant Garamond' },
    { name: 'IBM Plex Sans' },
    { name: 'Zilla Slab' },
    { name: 'Asap' },
    { name: 'Saira' },
    { name: 'Exo 2' },
    { name: 'Barlow' },
    { name: 'Droid Sans' },
    { name: 'Arvo' },
    { name: 'Catamaran' },
    { name: 'Chivo' },
    { name: 'Kumbh Sans' },
    { name: 'Karla' },
    { name: 'Yanone Kaffeesatz' },
    { name: 'Hind' },
    { name: 'Fjalla One' },
    { name: 'Signika' },
    { name: 'Rokkitt' },
    { name: 'Abril Fatface' },
    { name: 'Manrope' },
    { name: 'DM Sans' },
    { name: 'Jost' },
    { name: 'Varela' },
    { name: 'Acme' },
    { name: 'Josefin Slab' },
    { name: 'Alata' },
    { name: 'Play' },
    { name: 'Alice' },
    { name: 'Red Hat Display' },
    { name: 'Amatic SC' },
    { name: 'Abel' },
    { name: 'Passero One' },
    { name: 'Francois One' },
    { name: 'Fira Code' },
    { name: 'Balsamiq Sans' },
    { name: 'Spectral' },
    { name: 'Archivo' },
    { name: 'Patrick Hand' },
    { name: 'Changa' },
    { name: 'Epilogue' },
    { name: 'Urbanist' },
    { name: 'Prata' },
    { name: 'Sen' },
    { name: 'DM Serif Display' },
    { name: 'Cardo' },
    { name: 'Sora' },
    { name: 'Vibur' },
    { name: 'Knewave' },
    { name: 'Baloo 2' },
    { name: 'Spartan' },
    { name: 'Anson' },
    { name: 'Assistant' },
    { name: 'Staatliches' },
    { name: 'Markazi Text' },
    { name: 'Yeseva One' },
    { name: 'Almendra Display' },
    { name: 'Armata' },
    { name: 'Averia Gruesa Libre' },
    { name: 'Baumans' },
    { name: 'Bowlby One SC' },
    { name: 'Bungee Inline' },
    { name: 'Chela One' },
    { name: 'Chilanka' },
    { name: 'Cinzel Decorative' },
    { name: 'Codystar' },
    { name: 'Comfortaa' },
    { name: 'Coming Soon' },
    { name: 'Satisfy' },
    { name: 'Concert One' },
    { name: 'Cormorant Unicase' },
    { name: 'Croissant One' },
    { name: 'Delius Unicase' },
    { name: 'Eater' },
    { name: 'Emilys Candy' },
    { name: 'Euphoria Script' },
    { name: 'Fascinate' },
    { name: 'Flamenco' },
    { name: 'Fredericka the Great' },
    { name: 'Fugaz One' },
    { name: 'Gloria Hallelujah' },
    { name: 'Great Vibes' },
    { name: 'Hanalei' },
    { name: 'Iceland' },
    { name: 'Jim Nightshade' },
    { name: 'Jolly Lodger' },
    { name: 'Kavoon' },
    { name: 'Lobster Two' },
    { name: 'MedievalSharp' },
    { name: 'Monoton' },
    { name: 'Mystery Quest' },
    { name: 'Pirata One' },
    { name: 'Poller One' },
    { name: 'Raleway Dots' },
    { name: 'Rammetto One' },
    { name: 'Rye' },
    { name: 'Sail' },
    { name: 'Sarina' },
    { name: 'Seaweed Script' },
    { name: 'Slackey' },
    { name: 'Snowburst One' },
    { name: 'Swanky and Moo Moo' },
    { name: 'Vast Shadow' },
    { name: 'Wellfleet' },
    { name: 'Yeseva One' },
    { name: 'Zeyada' }
  ].sort((a, b) => a.name.localeCompare(b.name));  
  
  private fontsLoaded: Font[] = [];

  private WebFont !: any

  public fontSubject: BehaviorSubject<Array<Font>> = new BehaviorSubject<Array<Font>>([]);


public loadFont(font: Font): void {
    if (isPlatformBrowser(this.platformId)) {
       
      const matchingFont : Font | undefined = this.fontsLoaded.find(f => f.name === font.name);
      if(matchingFont){
        return
      }else{
        this.fontsLoaded.push(font)
      }

        try {
            //@ts-ignore
            this.WebFont = require('../util/webfontloader');

            this.WebFont.load({
                google: {
                    families: [font.name],
                },
                fontactive: () => {
                    this.addUsedFont(font);
                },
                timeout: 2000,
            });
        } catch (error) {
            console.error('Error loading WebFont:', error);
        }
    }
}

  public loadSubset(){
    const subset: { name: string }[] = [
      { name: 'Anton' },
      { name: 'Lato' },
      { name: 'Permanent Marker' },
      { name: 'Caveat' },
      { name: 'Source Code Pro' },
      { name: 'Cormorant Garamond' },
      { name: 'Amatic SC' }
    ]
    this.loadFonts(subset)
  }

  async loadFonts(subset ?: Font[]) {
    if (isPlatformBrowser(this.platformId)) {
      if(this.evaluateEmit(subset))return
      try {
        //@ts-ignore 
        this.WebFont = require('../util/webfontloader');

        this.WebFont.load({
          google: {
            families: subset ? subset.map((font: Font) => font.name) :this.fonts.map((font: Font) => font.name),
          },
          timeout: 2000,
          active: () => {
            console.log('All fonts loaded successfully');
            // Update styles for all fonts
          },
          inactive: () => {
            console.error('Some fonts failed to load');
            // Handle overall font loading failure
          },
          fontactive: (family: string) => {
            console.log(`Font '${family}' loaded`);
            const matchingFont : Font | undefined = this.fonts.find(font => font.name === family);
            if(matchingFont){
              this.fontsLoaded.push(matchingFont)
              this.evaluateRemove(family, subset)
              this.evaluateEmit(subset)
            }
          },
          fontinactive: (family: string) => {
            console.error(`Font '${family}' failed to load`);
            this.evaluateRemove(family, subset)
            this.evaluateEmit(subset)
          }
        });
      } catch (error) {
        console.error('Error loading WebFont:', error);
      }
    }
  }

  public hasAllFonts() : boolean{
    const toReturn : boolean =  this.evaluateEmit()
    return toReturn
  }

  private evaluateEmit(subset ?: Font[]) : boolean {
    if(subset){
      subset = subset.filter(s => !this.fontsLoaded.some(l => l.name === s.name))
      const allFontsExist : boolean = subset.every(font => this.fontsLoaded.some(loadedFont => loadedFont.name === font.name))
      if(allFontsExist){
        this.fontSubject.next(this.fontsLoaded.sort((a, b) => a.name.localeCompare(b.name)))
        return true
      }
    }else{
      this.fonts = this.fonts.filter(s => !this.fontsLoaded.some(l => l.name === s.name))
      if(this.fonts.length == 0){
        this.fontSubject.next(this.fontsLoaded.sort((a, b) => a.name.localeCompare(b.name)))
        return true
      }
    }
    return false
  }

  private evaluateRemove(family : string, subset ?: Font[]){
    if(subset){
      const indexToRemoveSubset = this.fonts.findIndex((font) => font.name === family);

      if(indexToRemoveSubset > -1){
        subset.splice(indexToRemoveSubset, 1);
      }
    }
    const indexToRemove = this.fonts.findIndex((font) => font.name === family);
    if(indexToRemove > -1){
      this.fonts.splice(indexToRemove, 1);
    }
  }


  private _usedFonts : Font[] = []
  addUsedFont(font : Font){
    this._usedFonts.push(font)
  }

  public removeUsedFont(font : Font){
    const index : number = this._usedFonts.indexOf(font)
    if(index > -1)this._usedFonts.splice(index, 1)
  }

  clearUsedFonts(){
    this._usedFonts.length = 1
  }

  public get usedFonts(): string[]{
    return this._usedFonts.map((font : Font) => font.name)
  }

  
}