import { AssetType } from "./GridSection"

export interface Action<T> {
    deepFried?: boolean
    scope?: any;
    callback?: (argument: T) => void;
    updateType?: UpdateType
    updateDestination?: UpdateDestination
    updateProp?: string
    updateMin?: number
    updateMax?: number
    updateStep?: number

    shouldShowScope?:any
    shouldShowCallback? : () => boolean
}

export enum UpdateDestination{
    ASSET = "asset",
    GRID = "grid",
    MEDIA = "media"
}

export enum UpdateType{
    COLORS = 'colors',
    SLIDER = 'slider',
    MENU = 'menu',
    DELETE = 'delete',
    BUTTON_GROUP = 'buttonGroup',
    FONT_PICKER = 'fontPicker',
    FONT_DECORATIONS = 'fontDecorations'
}

export enum DisplayType{
    ICON = 'icon',
    SVG = 'svg'
}

export class Pickable<T = any> {
   private _name ?: string
   public get name() : string | undefined{
        return this._name
   }
   public set name(n : string){
    this._name = n
   }

   public displayType : DisplayType = DisplayType.ICON


   public icon ?: any

   public data : T

   public action ?: Action<T>

   isDefault : boolean = false

   getData() : T{
        return this.data
   }

   constructor(name ?: string, icon : any = 'aspect_ratio', data ?: any, action ?: Action<T>, isDefault : boolean = false){
    this._name = name || ''
    this.isDefault = isDefault
    this.icon = icon
    this.data = data
    this.action = action
   }

   public toString() : string{
    return this?.name?.toString() || ''
   }
   
}



export class AspectRatio extends Pickable{
    public horizontalAspectRatio !: number
    public verticalAspectRatio !: number

    constructor(har : number, vasr : number, name ?: string, icon ?: string){
        super(name, icon)
        this.horizontalAspectRatio = har;
        this.verticalAspectRatio = vasr
    }

    public override get name() : string{
        return `${super.name && super.name.length > 0 ? super.name + ' (' : ''}${this.toString().split('/').join(':')}${super.name && super.name.length > 0 ?  ')' : ''}`
    }

    public override toString(): string {
        return `${this.horizontalAspectRatio} / ${this.verticalAspectRatio}`
    }
}

export class Border extends Pickable{
    constructor(name : string, icon ?: string){
        super(name, icon)
    }

    public override toString(): string {
        return super.name?.toLowerCase() || ''
    }
}

export class AssetPickable extends Pickable{
    constructor(name : string, icon ?: string, data ?: AssetType ){
        super(name, icon, data)
    }
}