import { Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { impl } from '../../libs/domtoimage/domtoimage';
import { Sticker, StickerPack, StickerService, StickerServiceResponse } from '../../service/sticker.service';
import { forkJoin, map, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { environment } from '../../../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { LocalSaveService } from '../../service/local-save.service';
import { Asset, AssetType, GridSection, Media, MediaType } from '../../vo/GridSection';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { StickerBrowserComponent } from '../sticker-browser/sticker-browser.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { PaidFeature, PaymentService } from '../../service/payment.service';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { LoggedInUserComponent } from '../logged-in-user/logged-in-user.component';
import { isPlatformBrowser } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { SavedMemesComponent } from '../saved-memes/saved-memes.component';
import { PopularMemesComponent } from '../popular-memes/popular-memes.component';
import { InfoDialogType, LegalComponent } from '../legal/legal.component';
import { CommonModule } from '@angular/common';
import { TopToolbarComponent } from '../top-toolbar/top-toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';
import { AppService } from '../../service/app.service';
import { MetaService } from '../../service/meta.service';


export enum ContentType{
  POPULAR = 1,
  SAVED = 2,
  BLANK = 3,
  CLASSIC = 4,
  GIF = 5,
  UPLOAD = 6,
}
@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDividerModule,
    LazyLoadDirective,
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    LoggedInUserComponent,
    CommonModule,
    TopToolbarComponent,
    MatCardModule,
    MatButtonModule
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements OnInit {

  @ViewChild('trigger') public trigger ?: MatAutocompleteTrigger

  public allOptions?: StickerPack[]
  public filteredOptions: Sticker[] | any = [{}]
  public searchInput: FormControl = new FormControl('');
  public hasSaved : boolean = false

  public shouldCollapse() : boolean{
    return this.deviceDetector.lessThanHeight(575)
  }

  readonly ContentType = ContentType


  public isReady : boolean = false
//this.localSaveService.getAll().pipe(take(1)).subscribe((val: GridSection[]) => { this.savedMemes = val.length })
  constructor(private meta : MetaService, private appService : AppService, private analytics : AnalyticsService, public localSaveService : LocalSaveService, private stickerService: StickerService, private destroyRef: DestroyRef, private router: Router, private localSave : LocalSaveService, private dialog : MatDialog, public deviceDetector : DeviceDetectorService, private paymentService: PaymentService, private authService : DankTankAuthService,  @Inject(PLATFORM_ID) private platformId: Object) {
    this.meta.setDefaultMetaTags()
    if(isPlatformBrowser(platformId)){
      localSave.getAll().pipe(take(1)).subscribe((val : GridSection[]) =>{
        if(val.length > 0)this.hasSaved = true
      })

      this.dialog.open(LoaderComponent, { disableClose: true, width: '200px' }).afterClosed().pipe(take(1)).subscribe(d => {
        this.isReady = true
      })
    }else{
      //this.isReady = true
    }
  }

  public focused : boolean = false

  public onBlur(){
    this.focused = false
    this.stop()
  }

  public onFocus(){
    //this is so the opening animation works
    const tempOptions : Sticker[] =  [...this.filteredOptions]
    this.filteredOptions.length = 0
    this.focused = true
    setTimeout(() => {
      this.filteredOptions = tempOptions
      this.trigger?.openPanel()
      this.cycleThroughStrings()
    }, 300);
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
    forkJoin({
      memeResponse: this.stickerService.getMemePackData().pipe(take(1)),
      gifResponse: this.stickerService.getGifPackData().pipe(take(1))
    }).pipe(
      map(({ memeResponse, gifResponse }) => {
        const collection: StickerPack[] = [];
  
        if (memeResponse.data) {
          memeResponse.data.collection = memeResponse.data.collection.map(pack => ({
            ...pack,
            stickers: pack.stickers.map(sticker => ({
              ...sticker,
              type : 'meme'
            }))
          }));
          collection.push(...memeResponse.data.collection);
        }
  
        if (gifResponse.data) {
          gifResponse.data.collection = gifResponse.data.collection.map(pack => ({
            ...pack,
            stickers: pack.stickers.map(sticker => ({
              ...sticker,
              type : 'gif'
            }))
          }));
          collection.push(...gifResponse.data.collection);
        }
        return collection;
      })
    ).subscribe((combinedCollection: StickerPack[]) => {
      this.allOptions = combinedCollection;
      this.findMostUsed()
    });
  
    this.searchInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const val: string = this.searchInput.value;
      if(val.length < 3){
        this.cycleThroughStrings()
        this.filteredOptions = [{}]
      }else{
        this.stop()
        this.filteredOptions = this.filterStickersByString(val);
      }
      //this.filteredOptions = val.length < 3 ? [{}] : this.filterStickersByString(val);
    });
  }
  }

  private findMostUsed():void{
    //find most used words longer than three characters that occur more than four times
    const map = new Map<string, number>();
    const mostUsed : string[] = []
    this.allOptions?.forEach((pack : StickerPack) => {
      pack.stickers.forEach(element => {
        const words : string[] = element.name.toLowerCase().split(" ")
        words.forEach((word : string) => {
          if(word.length > 3){
            if(map.get(word)){
              map.set(word, (map.get(word) as number) + 1)
            }else{
              map.set(word, 1)
            }
          }
        })
      });
    })
    for (const [key, value] of map) {
      if(value > 4 && !this.holderStrings.includes(key))this.holderStrings.push(key)
      //console.log(`${key}: ${value}`);
    }
    //randomize the array
    for (let i = this.holderStrings.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.holderStrings[i], this.holderStrings[j]] = [this.holderStrings[j], this.holderStrings[i]];
    }
  }


  private cache = new Map<string, Sticker[]>();
  public filterStickersByString(searchString: string, isCode : boolean = false): Sticker[] {
    if(!isCode){
      const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.SEARCH, info : searchString}
      this.analytics.track(action)
    }

    const searchTerm = searchString.toLowerCase();

    // Check if the result is cached and handle undefined
    const cachedResult = this.cache.get(searchTerm);
    if (cachedResult) {
        return cachedResult;
    }

    // Preprocess search terms
    const searchTerms = searchTerm.split(' ');

    // Filter stickers based on search terms
    const uniqueStickers = (this.allOptions || [])
        .flatMap(stickerPack => stickerPack.stickers)
        .filter(sticker => {
            const stickerName = sticker.name.toLowerCase();
            const stickerTags = sticker.tags ? sticker.tags.toLowerCase() : '';
            return searchTerms.some(val => {
                // Only return if more than 3 characters of the search term are included
                return val.length > 2 && (stickerName.includes(val) || stickerTags.includes(val));
            });
        })
        .reduce((acc, sticker) => {
            const key = `${sticker.name.toLowerCase()}-${sticker.type}`;
            if (!acc.seen.has(key)) {
                acc.seen.add(key);
                acc.unique.push(sticker);
            }
            return acc;
        }, { seen: new Set<string>(), unique: [] as Sticker[] })
        .unique;

    // Sort unique stickers by name with exact match prioritization
    uniqueStickers.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA === searchTerm && nameB !== searchTerm ? -1 :
               nameB === searchTerm && nameA !== searchTerm ? 1 :
               nameA.localeCompare(nameB);
    });

    // Cache the result
    this.cache.set(searchTerm, uniqueStickers);

    return uniqueStickers;
}

  public getThumbFromSticker(sticker : Sticker):string{
    switch(sticker.type){
      case 'gif':
        return `${environment.storage}media.danktank.cloud/gifs%2Fthumb%2F${sticker.storageReference}.webp?alt=media`
      case 'meme':
        return `${environment.storage}media.danktank.cloud/memes%2Fthumb%2F${sticker.storageReference}`
    }
  }

  public openFromSticker(sticker : Sticker){
    console.log()
    if(sticker.type == 'gif'){
      this.router.navigate(['generator', 'meme', sticker?.name], { queryParams: { format: 'gif' } });
    }else if(sticker.type == 'meme'){
      this.router.navigate(['generator', 'meme', sticker?.name], { queryParams: { format: 'meme' } });
    }
  }

  private alreadyLoadedMap: Map<HTMLImageElement, Sticker> = new Map<HTMLImageElement, Sticker>();
  public getRandomGif(img : HTMLImageElement): Sticker {
    if(!this.alreadyLoadedMap.get(img)){
      const gifs = this.allOptions?.flatMap(pack => pack.stickers.filter(sticker => sticker.type === 'gif')) || [];
      if (gifs.length === 0) {
        throw new Error('No Meme stickers available');
      }
      const toReturn : Sticker = gifs[Math.floor(Math.random() * gifs.length)];
      this.alreadyLoadedMap.set(img, toReturn)
      return toReturn
    }else{
      return this.alreadyLoadedMap.get(img) as Sticker
    }
  }
  
  public getRandomMeme(img : HTMLImageElement): Sticker {
    if(!this.alreadyLoadedMap.get(img)){
      const memes = this.allOptions?.flatMap(pack => pack.stickers.filter(sticker => sticker.type === 'meme')) || [];
      if (memes.length === 0) {
        throw new Error('No Meme stickers available');
      }
      const toReturn : Sticker = memes[Math.floor(Math.random() * memes.length)];
      this.alreadyLoadedMap.set(img, toReturn)
      return toReturn
    }else{
      return this.alreadyLoadedMap.get(img) as Sticker
    }
  }






  ctaClicked(ct : ContentType){
    switch(ct){
      case ContentType.POPULAR:
        this.showPopularMemes()
        break
      case ContentType.CLASSIC:
        this.openMemeBackgroundBrowser(AssetType.MEME, MediaType.LOCAL_IMAGE)
        break
      case ContentType.UPLOAD:
        this.showUpload()
        break
      case ContentType.SAVED:
        this.showSavedMemes()
        break
      case ContentType.BLANK:
        this.openBlank()
        break
      case ContentType.GIF:
        this.openGif()
        //this.authService.authThen(this.openGif, this, this.paymentService.getPro(), PaidFeature.GIFS)
        break
    }
  }

  private showUpload() {
    this.router.navigate(['generator', 'meme'], {
      queryParams: { ['action']: 'upload' },
    });
  }

  private showSavedMemes() {
    this.router.navigate(['generator', 'meme'], {
      queryParams: { ['action']: 'saved' },
    });
  }
  
  showPopularMemes() {
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : "Popular Memes"}
    this.analytics.track(action)
    this.dialog.open(PopularMemesComponent, { data: this, })
  }

  private openGif(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : "GIFS"}
    this.analytics.track(action)
    this.openMemeBackgroundBrowser(AssetType.GIF, MediaType.VIDEO)
  }

  private openBlank(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.CLICK, info : "Blank Meme"}
    this.analytics.track(action)
    this.appService.gridSectionSimple = new GridSection()
    this.router.navigate(['generator', 'meme'])
  }

  public showLegal(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : "Legal"}
    this.analytics.track(action)
    this.dialog.open(LegalComponent, { minWidth: '85vw', data : InfoDialogType.TOC })
  }


  private openMemeBackgroundBrowser(assetType : AssetType, mediaType : MediaType){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : `${assetType} Browser`}
    this.analytics.track(action)
    const gs : GridSection = new GridSection()
    gs.verticalAspectRatio = gs.horizontalAspectRatio = 1
    const m : Media = {loaded : false, blur : 0, sepia : 0, grey : 0, hue : 0, saturation : 1, brightness: 1, mediaType : mediaType, mediaLocation : '', originalLocation : ''}
    gs.media = m
      const asset : Asset = {type : assetType, data : {x : 0, y :0, visible : true, editing : false}, convertToMedia : m, section : gs}
      this.dialog.open(StickerBrowserComponent, { 
        data : asset,
        panelClass : 'panel-class',
        minHeight : '100px',
        maxHeight : '80vh',
        minWidth : this.deviceDetector.isPortrait() ? '95vw' : '600px',
        maxWidth : '95vw'
      })
  }

  public memeAtRandom(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.CLICK, info : "Random Meme"}
    this.analytics.track(action)
    const memes = this.allOptions?.flatMap(pack => pack.stickers.filter(sticker => sticker.type === 'meme')) || [];
    const meme : Sticker = memes[Math.floor(Math.random() * memes.length)];
    if(meme){
      this.router.navigate(['generator', 'meme', meme?.name], { queryParams: { format: 'meme' } })
    }
  }







  //STUFF TO DO SEARCH PROMPT TYPING

  private _holderSearch: string = '';
public set holderSearch(val: string) {
    if (!this.searchInput.value || this.searchInput.value.length < 3) {
        this.filteredOptions = [{}, ...this.filterStickersByString(val, true)];
    }
    this._holderSearch = val;
}
public get holderSearch(): string {
    return this._holderSearch;
}
private readonly holderStrings: string[] = ['cat', 'spongebob', 'hawk tuah', 'simpsons', 'obama', 'interesting'];
private typingSpeed: number = 150; // Adjust typing speed (milliseconds per character)
private pauseTime: number = 3000; // 3 seconds pause after fully typing a word
private isTyping: boolean = false;
private typingPromise: Promise<void> | null = null;
private timeoutId: number | null = null; // Store the current timeout ID

private async cycleThroughStrings(): Promise<void> {
    if (this.isTyping) return; // Prevent multiple starts

    this.isTyping = true;

    while (this.isTyping) {
        for (const word of this.holderStrings) {
            if (!this.isTyping) return; // Stop typing immediately if requested

            await this.typeWord(word);

            if (!this.isTyping) return; // Stop typing immediately if requested

            await this.pause(this.pauseTime); // Pause after typing a word
        }
    }
}

private async typeWord(word: string): Promise<void> {
    this.holderSearch = ''; // Clear the previous word
    for (let i = 0; i < word.length; i++) {
        if (!this.isTyping) return; // Stop typing immediately if requested

        this.holderSearch += word[i];
        await this.pause(this.typingSpeed); // Pause between characters
    }
}

private pause(ms: number): Promise<void> {
    return new Promise(resolve => {
        if (!this.isTyping) {
            resolve(); // Resolve immediately if typing has stopped
            return;
        }

        this.clearExistingTimeout(); // Clear any existing timeout before setting a new one

        this.timeoutId = window.setTimeout(() => {
            resolve(); // Resolve the promise after the timeout
            this.timeoutId = null; // Clear the timeout ID
        }, ms);
    });
}

private clearExistingTimeout(): void {
    if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId); // Clear any existing timeout
        this.timeoutId = null; // Reset the timeout ID
    }
}

public stop(): void {
    this.holderStrings.length = 0
    this.findMostUsed()
    this.isTyping = false; // Signal to stop typing
    this.clearExistingTimeout(); // Clear any active pause
    this.holderSearch = ''; // Clear the search string immediately

    if (this.typingPromise) {
        this.typingPromise = this.typingPromise.finally(() => {
            this.typingPromise = null; // Ensure the promise is reset
        });
    } else {
        this.typingPromise = null; // Reset the typing promise if not active
    }
}

}






















